/* eslint-disable max-lines */
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select } from 'redux-saga/effects'

import app from '../../config/app'
import Event from '../../data/models/Event'
import Share from '../../data/models/Share'
import EventQueries from '../../graphql/query/Event/index'
import { eventJsonLd } from '../../helpers/JsonLdHelpers'
import { assetUrl, routeParamSlug } from '../../helpers/UrlHelpers'
import i18n from '../../i18n'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions, pageType, resourceType, selectors as AppSelectors } from '../../redux/app/redux'
import routes, { Router } from '../../routes'

import { actions as EventActions } from './redux'


export default class EventSagas {

  static* init({ asPath, query, res }) {
    return yield call(EventSagas.loadEvent, query, asPath, res)
  }

  // eslint-disable-next-line complexity
  static* loadEvent({ slug = '/' }, asPath, res) {
    yield put(showLoading())
    const errors = []

    // Event
    const result = yield call(ApiSagas.query, EventQueries.eventByFullSlug, { slug })

    // Check if errors
    if (result.errors && result.errors.length) {
      result.errors.map((error) => errors.push(error))
      if (result.errors[0].message === 'You are not authorized to view this event') {
        console.log('[EVENT] 401 error loading data', result.errors)
        yield put(hideLoading())
        return { error: 401 }
      }
    }

    const returnedData = yield call(EventSagas.transformData, result.data?.eventByFullSlug)
    const breadCrumb = yield call(EventSagas.transformBreadcrumb, returnedData)
    const jsonld = yield call(EventSagas.transformJsonld, returnedData)
    const seo = yield call(EventSagas.transformSeo, returnedData)

    yield all([
      put(EventActions.setData(returnedData)),
      put(AppActions.setResource(resourceType.EVENT, result.data?.eventByFullSlug?.id)),
      put(AppActions.setBreadcrumb(breadCrumb)),
      put(AppActions.setJsonld(jsonld)),
      put(AppActions.setSeo(seo)),
    ])

    yield all([
      put(EventActions.setLoaded(true)),
      put(hideLoading()),
    ])

    // Check errors
    if (!result.data?.eventByFullSlug && errors && errors.length >= 1) {
      console.log('[EVENT] 404 error loading data', errors)
      yield put(hideLoading())
      return { error: 404 }
    }

    if (returnedData === null) {
      console.log('[EVENT] 404 error loading data', result)
      yield put(hideLoading())
      return { error: 404 }
    }
  }

  static transformData(data) {
    if (!data) {
      return null
    }

    const event = Event(data)

    return {
      ...data,
      event,
    }
  }

  static* transformBreadcrumb(data) {
    if (data) {
      const pages = yield select(AppSelectors.pages)

      return [
        {
          label: pages[pageType.HOME].title,
          title: pages[pageType.HOME].title,
          link: pages[pageType.HOME].link,
          route: routes.page.name,
          routeParams: { slug: routeParamSlug(pages[pageType.HOME].link) },
        },
        {
          label: pages[pageType.EVENTS].title,
          title: pages[pageType.EVENTS].title,
          link: pages[pageType.EVENTS].link,
          route: routes.page.name,
          routeParams: { slug: routeParamSlug(pages[pageType.EVENTS].link) },
        },
        {
          label: data.event.title,
          title: data.event.title,
        },
      ]
    }

    return []
  }

  static transformJsonld(data) {
    if (data) {
      return eventJsonLd(data)
    }

    return {}
  }

  static transformSeo(data) {
    if (data && data.share) {
      const transformedShare = Share(data.share)
      const title = i18n.t('SEO_event_title', {
        name: data.name.length >= 30 ? `${data.name.substring(0, 30)}…` : data.name,
      })
      const description = i18n.t('SEO_event_description', { name: data.name })

      return {
        ...transformedShare,
        title,
        description,
        url: `${app.APP_URL}${Router.getRouteUrl(routes.event, { slug: data.slug })}`,
        image: transformedShare.image ? assetUrl(transformedShare.image, { width: 1024 }) : '',
        noindex: data.visibility !== 'everyone',
      }
    }

    return {}
  }

  static* loop() {
    yield all([
      //
    ])
  }

}
